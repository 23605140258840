import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-21220029"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "slide-container" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src", "type"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue3_markdown_it = _resolveComponent("vue3-markdown-it")!
  const _component_audio_player = _resolveComponent("audio-player")!
  const _component_ion_slide = _resolveComponent("ion-slide")!
  const _component_ion_slides = _resolveComponent("ion-slides")!

  return (_openBlock(), _createBlock(_component_ion_slides, {
    class: "slides-container",
    pager: "true",
    options: _ctx.slideOpts,
    onIonSlideWillChange: _ctx.pauseAudio,
    ref: "slides"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slideContents, (slideContent) => {
        return (_openBlock(), _createBlock(_component_ion_slide, {
          key: slideContent.headerText
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", {
                class: "slide-content",
                style: _normalizeStyle(slideContent.audioSrc ? 'height:calc(100% - 150px)' : 'height:calc(100% - 50px)')
              }, [
                (slideContent.imageSrc && !_ctx.isVideo(slideContent.imageSrc))
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: slideContent.imageSrc,
                      alt: ""
                    }, null, 8, _hoisted_2))
                  : _createCommentVNode("", true),
                (slideContent.imageSrc && _ctx.isVideo(slideContent.imageSrc))
                  ? (_openBlock(), _createElementBlock("video", {
                      key: 1,
                      autoplay: "",
                      loop: "",
                      playsinline: "",
                      muted: "",
                      onLoadedmetadata: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.metadataLoaded && _ctx.metadataLoaded(...args))),
                      onCanplaythrough: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.fullyLoaded && _ctx.fullyLoaded(...args)))
                    }, [
                      _createElementVNode("source", {
                        src: slideContent.imageSrc,
                        type: _ctx.getContentType(slideContent.imageSrc)
                      }, null, 8, _hoisted_3)
                    ], 32))
                  : _createCommentVNode("", true),
                _createVNode(_component_vue3_markdown_it, {
                  source: slideContent.contentText,
                  breaks: ""
                }, null, 8, ["source"])
              ], 4),
              _createElementVNode("div", {
                class: "slide-footer",
                style: _normalizeStyle(slideContent.audioSrc ? 'height:150px' : 'height:40px'),
                ref_for: true,
                ref: "slideFooter"
              }, [
                _createVNode(_component_audio_player, {
                  "audio-data": slideContent.audioSrc,
                  "start-time-seconds": _ctx.audioStartTime,
                  "end-time-seconds": _ctx.audioEndTime,
                  color: "--color-primary"
                }, null, 8, ["audio-data", "start-time-seconds", "end-time-seconds"])
              ], 4)
            ], 512), [
              [_vShow, _ctx.showSlideContent]
            ])
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }, 8, ["options", "onIonSlideWillChange"]))
}