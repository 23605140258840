import { v4 as uuidv4 } from "uuid";
import { decrypt, decryptEntry, encrypt, encryptEntry } from "../services/SecurityService";
import CryptoJS from "crypto-js/core";
import {getAWSUrl, getFromS3} from '@/services/AWSService';
import {store} from "@/store";
import {getImageDataUri} from "@/utilities/Compatibility";
import Worker from 'worker-loader!../background-worker/worker';
import {isConnectedToInternet} from '@/utilities/Network';
import {Device} from '@capacitor/device';

const DB_NAME = "CalcApp";
const LANGUAGE_KEYS = ["language", "buildConfigs", "calculator", "application", "menu", "bigPic", "journeyHelpPages", "onboardingPages", "learnWhereToStartPages", "privacyPage", "coachPage", "securityPage"];
let DB: IDBDatabase;

const worker = new Worker();
worker.addEventListener("message", (event: MessageEvent) => {
    store.dispatch("incrementBackgroundJourneySets",event.data);
});

/**
 * Current index db version
 */
export function getDBVersion(): number {
    return DB?.version ?? 0;
}

/**
 * Decrypt and parse to json
 * @param toDecrypt an encrypted string
 */
function decryptToJson(toDecrypt: string) {
    return JSON.parse(decrypt(toDecrypt).toString(CryptoJS.enc.Utf8));
}

/**
 * Empty db check for initialization
 * @param DB index db to check
 */
function needToLoadData(DB: any) {
    return new Promise((resolve) => {
        const trans = DB.transaction("media", "readonly");

        const store = trans.objectStore("media");
        const request = store.count();
        let returnValue: any;

        request.onsuccess = function() {
            returnValue = request.result === 0;
        };
        trans.oncomplete = () => {
            resolve(returnValue);
        };
    });
}

/**
 * Save defaults into db
 * @param storeName db store
 * @param object payload to save
 * @param DB db to save in
 */
function storeDefaults(storeName: string, object: Media | JourneySet | Quadrant | Journey | Language | string[], DB: any) {
    return new Promise<void>((resolve) => {
        const trans = DB.transaction([storeName], "readwrite");

        const store = trans.objectStore(storeName);
        if (store.autoIncrement)
            store.put(object);
        else
            store.put(object, 1);
        trans.oncomplete = () => {
            resolve();
        };
    });
}

/**
 * Read local file, returns json 
 * @param filePath local path to file
 */
async function readFile(filePath: string) {
    let file;
    try{
        file = await fetch(filePath);
    }catch (error){
        return undefined;
    }
    return await file.json();
}

/**
 * Empty live path reads local, is non-empty only when called from the first setLanguageFile.
 */

async function getLanguageFileData() {
    let livePath = '';
    const deviceInfo = await Device.getInfo();
    if (deviceInfo.platform === 'web') {
        livePath = 'language_encrypted_PWA.json';
    //store isn't set until after the 'local' language file read allowing for the initial load of the apps
    } else if(!store.state.isSecure) {
        if(deviceInfo.platform === "ios") {
            livePath = 'language_encrypted_iOSAppStore.json';
        } else {
            livePath = 'language_encrypted_androidAppStore.json'
        }
    } else if(store.state.secureUpdateOverride){
        livePath = 'language_encrypted.json';
    }

    return getFileData('./assets/data/language.json', livePath);
}

/**
 * @param filePath local path to file
 * @param livePath aws file name, must always be '' if secure and not configured to allow internet
 * @param useHost Whether to use the application instance bucket (useHost = true) or Cloudfront Download Domain (useHost = false) 
 */
async function getFileData(filePath: string, livePath: string, useHost = true) {
    await store.dispatch('setPlatform');
    const deviceInfo = await Device.getInfo();
    let toReturn = {} as any;
    //(`Retrieving ${filePath} (livepath: ${livePath})`);
    //Offline app always needs to read local, also first load read local to initialize persisted variables
    //console.log("Retrieving: " + filePath);
    //console.log("livePath: " + livePath);
    if (deviceInfo.platform === 'web' || (livePath !== '' && await isConnectedToInternet(false))) {
        const rootLanguageFolder = await getRootLanguageFolder();
        
        const fileData = await getFromS3( rootLanguageFolder, `${livePath}?timestamp=${new Date().getTime()}`, 'json', useHost);
        toReturn = fileData.data;
    } else {
        toReturn = (await readFile(filePath)) as any;
    }
    return toReturn;
}

/**
 * Returns application instance aka. root language
 */
async function getRootLanguageFolder(): Promise<string> {
    const deviceInfo = await Device.getInfo();
    if (deviceInfo.platform === 'web') {
        // Web versions are always hosted on their 'root' path
        const host = window.location.hostname;
        return host === 'localhost' ? 'discoverystudies' : host.split(".")[0];
    } else {
        return store.state.variant;
    }
}

//Local file is always encrypted, secure app always reads local, non-secure uses un-encrypted aws file when online
async function getManifestFileData() {
    let livePath = ''; 
    if (store.state.secureUpdateOverride === true || !store.getters.isSecure()){
        livePath = 'journeySetMetadata.json';
    }

    const fileData = await getFileData('./assets/data/journeySetMetadata.json', livePath, false); 
    //Check for missing manifest file when offline return empty on fail
    if(!fileData){
        return [];
    }
    
    if((store.state.isSecure && !store.state.secureUpdateOverride) || !(await isConnectedToInternet(false))) {
        return parseEncryptedArray(fileData.content);
    }
    return fileData;
}

async function initPreferences(config: any) {
    const preferences = store.getters.getPreferences();
    preferences.shouldDisplayInternetAlert = config.shareInternetWarning === "true";
    preferences.shouldDisplayUnencryptedAlert = config.shareUnencryptedWarning === "true";
    await store.dispatch("setPreferences", preferences);
}

/**
 * Decryption helper for arrays
 * @param chunks array of encrypted strings 
 */
function parseEncryptedArray(chunks: string[]) {
    let quadrantString = '';
    chunks.forEach((chunk: string) => {
        quadrantString += decryptToJson(chunk);
    });
    return JSON.parse(quadrantString);
}

/**
 * Saves meta data related to manifest file into the database and calls background worker for additional processing
 * @param metaData parsed journeySetMetadata.json Data
 */
async function saveJourneySetMetaData(metaData: any[]) {
    const defaultSets: string[] = [];
    //console.log("savingJourneySetMetaData");
    for(const metaJourneySet of metaData) {        
        let journeySet: JourneySet | null = await getJourneySet(metaJourneySet.guid);
        const noJourneySet = !journeySet;
        //legacy journey sets may not have published dates but should process correctly here as null === null after first load
        if(journeySet?.alreadyDownloaded && 
            (journeySet?.published === metaJourneySet.published || metaJourneySet.published === null)) {
            continue;
        }
        
        if (!noJourneySet) {
            //Shortcut default sets that haven't completed loading. The published date will match in publishDiff below 
            // hit the already loaded logic but really the background worker needs to do the work for updating them
            if(journeySet.isDefaultSet && (!journeySet.deleted && !journeySet.alreadyDownloaded)) {
                defaultSets.push(journeySet.id);
                continue;
            }
            //legacy sets can also come across as "" instead of null, don't reload those either; don't re-install any
            //user deleted
            if(!journeySet.published || journeySet.deleted) {
                continue;
            }
            //Journeyset updated, auto load changes
            const publishDiff = new Date(journeySet.published).getTime() - new Date(metaJourneySet.published).getTime();
            if (publishDiff >= 0) {
                continue;
            }
        }

        const isDefault = (metaJourneySet.IsSecureDefault && store.getters.isSecure() ||
            metaJourneySet.IsAppDefault && store.getters.isOnlineApp() ||
            metaJourneySet.IsPwaDefault && store.getters.isPwa());
        
        const alreadyDownloaded = journeySet?.alreadyDownloaded ?? false;   
        journeySet = {
            alreadyDownloaded: alreadyDownloaded,
            iconId: '',
            id: metaJourneySet.guid.toUpperCase(),
            isDefaultSet: isDefault,
            name: metaJourneySet.setname,
            numberOfJourneys: metaJourneySet.numberofjourneys,
            order: metaJourneySet.order,
            pdfName: '',
            quadrantId: metaJourneySet.quadrantId,
            tabContentImages: [],
            tabImages: [],
            updateAvailable: alreadyDownloaded,
            published: metaJourneySet.published,
            deleted: false,
            unavailableToDownload: false,
        };
        //Could further reduce space utilization on journeyset manifest icon changes by doing a delta check here
        if (metaJourneySet.icon) {
            journeySet.iconId = uuidv4();
            await saveMedia({ id: journeySet.iconId, content: metaJourneySet.icon || "" });
        }
        
        if(noJourneySet) {
            await saveJourneySet(journeySet);
        } else {
            await updateJourneySet(journeySet);
        }
        
        if(isDefault) {
            defaultSets.push(journeySet.id);
        }
    }
   
    callDefaultSetWorker(defaultSets, getDBVersion(), getAWSUrl(await getRootLanguageFolder(),''));
}

/**
 * Posts a message to background worker.ts file 
 * @param defaultSets Sets to load in the background
 * @param appVersion Current version of the database, required for synchronization
 * @param fetchUrl location to pull files from if online
 */
function callDefaultSetWorker(defaultSets: string[], appVersion: number, fetchUrl: string) {
    const payload = JSON.stringify({appVersion, fetchUrl, defaultSets, readFromAWS: !store.state.isSecure});
    worker.postMessage(payload);
}

/**
 * Retrieve and set the minimum data set required for application load
 * @param languageFileData parsed langauge.json file to process
 */
export async function getMinRequiredDataForAppLoad(languageFileData = {}): Promise<void> {
    const firstLoad = Object.keys(languageFileData).length === 0;
    store.dispatch("setLoadingValue", .4);
    let languageData = {};
    if(firstLoad){
        languageData = await getLanguageFileData() ;
    }else{
        languageData = languageFileData;
    }
    store.dispatch("setLoadingValue", .5);
    await setLanguageData(languageData, true);
    //First load will always be local, then re-load from online as needed
    if(store.getters.isOnlineApp() && await isConnectedToInternet(false) ){
        const s3Version = await getAppVersion();
        if (parseInt(s3Version) > store.state.appVersion){
            await reloadLanguage();   
        }
    }
}

/**
 * Used to update the language file for mobile apps after initialization
 */
export async function reloadLanguage(): Promise<void> {
    // Secure and PWA will always use the correct, updated language file on initialization
    if(( store.state.isSecure && !store.state.secureUpdateOverride)  || store.getters.isPwa()) {
        return;
    }
    const languageData = await getLanguageFileData()
    
    await setLanguageData(languageData, false);
}

/**
 * 
 * @param languageData
 * @param overridePreferences
 */
async function setLanguageData(languageData: any, overridePreferences: boolean) {
    let { locale } = languageData;
    const { content } = languageData;
    if (Object.keys(languageData).includes('appVersion')) {
        await store.dispatch("setAppVersion", languageData.appVersion);
    }

    store.dispatch("setLoadingValue", .6);

    const quadrants = parseEncryptedArray(content);

    store.dispatch("setLoadingValue", .7);

    locale = decryptToJson(locale);
    const buildConfigs = locale[Object.keys(locale)[0]].buildConfigs;
    buildConfigs.config = {};
    if (store.getters.isPwa()) {
        Object.assign(buildConfigs.config, buildConfigs.web);
    }
    else {
        Object.assign(buildConfigs.config, buildConfigs.mobile);
    }
    
    await store.dispatch("setMinRequiredLoadData", { locale: locale, quadrants: quadrants});
    if(overridePreferences) {
        await initPreferences(buildConfigs.config);
    }
}

/**
 * Index DB loader
 * @param locale parsed from language file
 * @param quadrants Base quadrants for the application home page
 * @param appVersion db version
 */
async function loadDBInBackground(locale: any, quadrants: Quadrant[], appVersion: string) {
    const isDBEmpty = await needToLoadData(DB);
    if (isDBEmpty) {
        await storeDefaults("recentJourneys", [], DB);
        await storeDefaults("fileUris", [], DB);
        //get and set journey quadrants
        quadrants.forEach((quadrant: Quadrant) => {
            let quadrantIconId = "";
            const useAltIcon = (quadrant.id === 'jf5' || quadrant.id === 'jf6') && store.getters.showAllQuadrants();
            if (quadrant.icon) {
                quadrantIconId = uuidv4();
                storeDefaults("media", encryptEntry({id: quadrantIconId, content: useAltIcon ? quadrant.altIcon : quadrant.icon}), DB);
            }
            
            storeDefaults("quadrant",
                encryptEntry({
                    id: quadrant.id,
                    name: quadrant.name,
                    altName: quadrant.altName,
                    iconId: quadrantIconId,
                }), DB
            );

            //get and set journey sets
            const {journeySets = []} = quadrant as any;
            journeySets.forEach((js: JourneySetToDisplay) => {
                let journeySetIconId = "";

                if (js.icon) {
                    journeySetIconId = uuidv4();
                    storeDefaults("media", encryptEntry({id: journeySetIconId, content: js.icon}), DB);
                }
                storeDefaults(
                    "journeySet",
                    encryptEntry({
                        id: js.id,
                        pdfName: js.pdfName,
                        name: js.name,
                        iconId: journeySetIconId,
                        quadrantId: quadrant.id,
                        isDefaultSet: true,
                        order: js.order,
                        tabContentImages: js.tabContentImages,
                        tabImages: js.tabImages,
                        numberOfJourneys: js.journeys?.length || 0,
                        published: js.published
                    }),

                    DB
                );

                //get and set journeys and journey tabs
                const {journeys = []} = js as any;
                journeys.forEach((j: Journey) => {
                    const {tabs = []} = j as any;
                    let journeyAudioId = "";
                    if (j.audioFile) {
                        journeyAudioId = uuidv4();
                        storeDefaults("media", encryptEntry({
                            id: journeyAudioId,
                            content: j.audioFile
                        }), DB);
                    }
                    storeDefaults(
                        "journey",
                        encryptEntry({
                                id: j.id,
                                name: j.name,
                                description: j.description,
                                audioFileId: journeyAudioId,
                                journeySetId: js.id,
                                isComplete: false,
                                tabs,
                                order: j.order
                            },
                            tabs,
                            "tabs"
                        ),
                        DB
                    );
                });
            });
        });

        // load default language
        for (const key of Object.keys(locale)) {
            const value = locale[key];
            delete value.buildConfigs.mobile;
            delete value.buildConfigs.web;
            for (const subKey of LANGUAGE_KEYS) {
                if (subKey === "language") {
                    await storeDefaults(subKey, encrypt(JSON.stringify({
                        locale: key,
                        messages: {language: value[subKey]}
                    })).toString(), DB);
                } else if (subKey === "application") {
                    value[subKey].appVersion = appVersion.toString();
                    await storeDefaults(subKey, encrypt(JSON.stringify(value[subKey])).toString(), DB);
                } else {
                    await storeDefaults(subKey, encrypt(JSON.stringify(value[subKey])).toString(), DB);
                }
            }

            const preferences = await getPreferences();
            preferences.shouldDisplayInternetAlert = value.buildConfigs.config.shareInternetWarning === "true";
            preferences.shouldDisplayUnencryptedAlert = value.buildConfigs.config.shareUnencryptedWarning === "true";
            await savePreferences(preferences);
            await store.dispatch("setPreferences", preferences);
        }
        
        await loadManifestMetaData();
    }else{
        if( store.state.versionUpdated == true){
            //update quadrants , fist remove existing media ids
            const existingQuadrants = (await getAllObjects("quadrant" , "fields" , true)) as Quadrant[];
            existingQuadrants.forEach((quadrant: Quadrant) => {
                if(quadrant.iconId){             
                    deleteMedia(quadrant.iconId)
                }
            });
            quadrants.forEach((quadrant: Quadrant) => {
                
                let quadrantIconId = "";
                const useAltIcon = (quadrant.id === 'jf5' || quadrant.id === 'jf6') && store.getters.showAllQuadrants();
                if (quadrant.icon) {
                    quadrantIconId = uuidv4();
                    storeDefaults("media", encryptEntry({id: quadrantIconId, content: useAltIcon ? quadrant.altIcon : quadrant.icon}), DB);
                }
                storeDefaults("quadrant",
                    encryptEntry({
                        id: quadrant.id,
                        name: quadrant.name,
                        altName: quadrant.altName,
                        iconId: quadrantIconId,
                    }), DB
                );
            });
        } 
        store.dispatch("setVersionUpdated" , false);
    }
}

export async function reloadManifestMetaData() {
    await loadManifestMetaData();
}

async function loadManifestMetaData() {
    const manifest = await getManifestFileData();
    await saveJourneySetMetaData(manifest);
}

export async function getLocalManifestJourneySetIds(): Promise<string[]> {
    const metaData = await getManifestFileData();
    const ids = [];
    for(const metaJourneySet of metaData) {
        ids.push(metaJourneySet.guid.toUpperCase());
    }
    return ids;
}

// Only call from live side of get file
async function getAppVersion(): Promise<string> {
    const host = window.location.hostname;
    const rootLanguageFolder = host.split(".")[0];
    const result = await getFromS3(rootLanguageFolder === 'localhost' ? 'discoverystudies' : rootLanguageFolder, `appVersion.txt?timestamp=${new Date().getTime()}`, 'text', true);
    return result.data;
}

//Empty means don't reload
async function checkForNewVersion(): Promise<any> {
    let languageData = {};
    //console.log("CheckForNewVersion");
    if (store.getters.checkForVersion()) {
        store.dispatch("setLoadingValue", .2);
        languageData = await getLanguageFileData();
        await store.dispatch("setCheckForVersion", false);
    }
    
    return languageData;
}

function clearObjectStore(db: any, transaction: any, store: string) {
    if (db.objectStoreNames.contains(store)) {
        transaction.objectStore(store).clear();
    }
}

function createObjectStore(db: any, store: string, params: any) {
    if(!db.objectStoreNames.contains(store)) {
        return db.createObjectStore(store, params);
    }
}

/**
 * Retrieves index db from local storage
 */
export async function getDb(): Promise<IDBDatabase> {
    //getPreferences() called from router/index.ts is the first to reach this function
    const languageData = await checkForNewVersion();
    if (DB && !languageData.appVersion) {
        return new Promise((resolve) => {
            resolve(DB);
        });
    }
    if (Object.keys(store.getters.getMinRequiredLoadData()).length === 0) {
        await getMinRequiredDataForAppLoad(languageData);
    }
    const { locale, quadrants} = store.getters.getMinRequiredLoadData();
    const appVersion = store.getters.getAppVersion();
    const request = window.indexedDB.open(DB_NAME, appVersion);

    return new Promise((resolve, reject) => {
        request.onerror = (e) => {
            console.error(e);
            reject("Error");
        };

        request.onsuccess = async (e) => {
            DB = (e as DataEvent).target.result;
            store.dispatch("setLoadingValue", .8);
            loadDBInBackground(locale, quadrants, appVersion);
            resolve(DB);
        };

        request.onupgradeneeded = (e) => {
            //console.log("New language file");
            const db = (e as DataEvent).target.result;
            const transaction = (e as DataEvent).target.transaction;
            store.dispatch("setVersionUpdated" , true);
            try {
                createObjectStore(db, "quadrant", {autoIncrement: true, keyPath: "id"});
                createObjectStore(db, "journeySet", {autoIncrement: true, keyPath: "id"});
                createObjectStore(db, "journey", {autoIncrement: true, keyPath: "id"});
                createObjectStore(db, "media", {autoIncrement: true, keyPath: "id"});
                createObjectStore(db, "recentJourneys", {autoIncrement: false});
                const fileUris = createObjectStore(db, "fileUris", {autoIncrement: false});
                if (fileUris) {
                    fileUris.add([], 1);
                }

                for (const key of LANGUAGE_KEYS) {
                    createObjectStore(db, key, {autoIncrement: false});
                }

                const preferences = createObjectStore(db, "preferences", {autoIncrement: false});
                if (preferences) {
                    const preferencesObject = store.getters.getPreferences();
                    const defaultPreferences = encryptEntry(preferencesObject);
                    preferences.put(defaultPreferences, 1);
                }
            } catch (e){
                clearObjectStore(db, transaction, "quadrant");
                clearObjectStore(db, transaction, "journeySet");
                clearObjectStore(db, transaction, "journey");
                clearObjectStore(db, transaction, "media");
                clearObjectStore(db, transaction, "recentJourneys");
                clearObjectStore(db, transaction, "fileUris");
                for (const key of LANGUAGE_KEYS) {
                    clearObjectStore(db, transaction, key);
                }
            }
        };
    });
}


/**
 * Generic retriever for all objects of a type
 * @param storeName Primary Type
 * @param subType Sub Type filter
 * @param shouldDecrypt will the result set be decrypted
 * @param keyNameForArray result name
 */
export async function getAllObjects(storeName: string, subType?: string, shouldDecrypt = true, keyNameForArray = ""): Promise<[]> {
    const db = await getDb();

    return new Promise((resolve) => {
        const fields: [] = [];
        const trans = db.transaction([storeName], "readonly");
        trans.oncomplete = () => {
            resolve(fields);
        };
        const objectStore = trans.objectStore(storeName);

        objectStore.openCursor().onsuccess = (e) => {
            const cursor = (e as DataEvent).target.result;
            if (cursor) {
                const quadrants = store.getters.quadrantsToShow();
                if (
                    (storeName == "quadrant" && subType == "fields" && quadrants.includes(cursor.key)) ||
                    (!store.getters.showAllQuadrants() && storeName == "quadrant" && subType == "learnMore" && ["jf5"].includes(cursor.key)) ||
                    (!store.getters.showAllQuadrants() && storeName == "quadrant" && subType == "moreTopics" && ["jf6"].includes(cursor.key)) ||
                    storeName !== "quadrant"
                )
                    if (shouldDecrypt) {
                        fields.push(decryptEntry(cursor.value, keyNameForArray ? cursor.value[keyNameForArray] : [], keyNameForArray) as never);
                    } else {
                        fields.push(cursor.value as never);
                    }
                cursor.continue();
            }
        };
    });
}

/**
 * Generic retriever for a single row
 * @param storeName Primary Type
 * @param key Primary Key
 * @param shouldDecrypt will the result be decrypted
 * @param keyNameForArray result name
 */
export async function getObject(storeName: string, key: any, shouldDecrypt = true, keyNameForArray = ""): Promise<any> {
    const db = await getDb();
    return new Promise((resolve) => {
        const trans = db.transaction([storeName], "readonly");
        const store = trans.objectStore(storeName);
        const dbRequest = store.get(key);
        let returnValue: any;

        dbRequest.onsuccess = function() {
            // it's possible we get an undefined as a result when doing a lookup, let's make sure it exists before we decrypt
            if (shouldDecrypt && dbRequest.result) {
                returnValue = decryptEntry(dbRequest.result, keyNameForArray ? dbRequest.result[keyNameForArray] : [], keyNameForArray);
            } else {
                returnValue = dbRequest.result;
            }
        };

        trans.oncomplete = () => {
            resolve(returnValue);
        };
    });
}

/**
 * Generic save into Index DB
 * @param storeName Primary Type
 * @param object payload
 * @param keyNameForArray name of array
 */
export async function saveObject(storeName: string, object: any, keyNameForArray = "") {
    const db = await getDb();

    return new Promise<void>((resolve) => {
        const trans = db.transaction([storeName], "readwrite");
        trans.oncomplete = () => {
            resolve();
        };

        const store = trans.objectStore(storeName);
        const encryptedObject = encryptEntry(object, keyNameForArray ? object[keyNameForArray] : [], keyNameForArray);
        if (store.autoIncrement)
            store.put(encryptedObject);
        else
            store.put(encryptedObject, 1);
    });
}

/**
 * Generic update into Index Db
 * @param storeName Primary Type
 * @param key Primary Key
 * @param updateData payload
 * @param shouldEncrypt will the object be encrypted in index db
 * @param keyNameForArray name of array
 */
export async function update(storeName: string, key: any, updateData: any, shouldEncrypt = false, keyNameForArray = "") {
    const db = await getDb();
    return new Promise<void>((resolve) => {
        const trans = db.transaction([storeName], "readwrite");

        const store = trans.objectStore(storeName);

        store.openCursor().onsuccess = (e) => {
            const cursor = (e as DataEvent).target.result;
            if (cursor) {
                if (cursor.key === key) {
                    if (shouldEncrypt) {
                        cursor.update(encryptEntry(updateData, keyNameForArray ? updateData[keyNameForArray] : [], keyNameForArray));
                    } else {
                        cursor.update(updateData);
                    }
                }
                cursor.continue();
            }
        };
        trans.oncomplete = () => {
            resolve();
        };
    });
}

/**
 * Generic delete from Index Db
 * *NOTE index db does not really delete
 * 
 * Open bug: reported size of indexdb is non-intuitive, does not match the real size, and appears to grow on open.
 * https://bugs.chromium.org/p/chromium/issues/detail?id=795735 
 *
 * Open bug: orphaned blob data:
 * https://bugs.chromium.org/p/chromium/issues/detail?id=1071482
 * 
 * Tombstomb sweeper & subsequent compaction of index db will not run until 2 seconds after all connections are closed. We currently do not close index db. Before rewriting the data storage implementation we could test a 'Reclaim space button' to close db connections and see if compaction occurs. If successful then all db access needs to be rethought and wrapped in an improved handler
 * https://chromiumdash.appspot.com/commit/e35c5d8a89687ca85a154d572cb8cd332c5f430d
 * 
 * Deletes don't delete:
 * google/leveldb: Issue #783
 * https://github.com/google/leveldb/issues/783
 *
 * Allocation defaults for Chromium are 33% of the harddrive, a single app is capped at 20% of that. If all 20% of the space quota is consumed 'Quota exceeded' errors will likely crash the app or worse.
 * https://developer.mozilla.org/en-US/docs/Web/API/Storage_API/Storage_quotas_and_eviction_criteria
 * @param storeName Primary Type
 * @param key Primary Key
 */
export async function deleteObject(storeName: string, key: string | number) {
    const db = await getDb();
    return new Promise<void>((resolve) => {
        const trans = db.transaction([storeName], "readwrite");
        trans.oncomplete = () => {
            resolve();
        };

        const store = trans.objectStore(storeName);
        store.delete(key);
    });
}


export async function deleteMedia(mediaId: string) {
    return deleteObject("media", mediaId);
}

export async function deleteQuadrant(quadrant: Quadrant) {
    return deleteObject("quadrant", quadrant.id);
}

/* 
    Soft delete if journeySet is still available online to maintain offline entry 
    Unavailable if soft deleting and not found in s3
*/
export async function deleteJourneySet(journeySetId: string, softDelete = false, unavailable  = false) {
    const currentRecentJourneys = await getObject("recentJourneys", 1, false);
    const journeys = await getJourneys(false, journeySetId);
    const journeyIds = journeys.map(journey => journey.id);
    const newRecentJourneys = currentRecentJourneys.filter((val: string) => {
        return !journeyIds.includes(val);
    });
    update("recentJourneys", 1, [...newRecentJourneys]);
    await Promise.all(
        journeyIds.map(async (journeyId) => {
            await deleteObject('journey', journeyId);
        })
    );
    const journeySet = await getJourneySet(journeySetId);
    if(journeySet.isDefaultSet || softDelete) {
        journeySet.deleted = true;
        journeySet.alreadyDownloaded = false;
        journeySet.unavailableToDownload = unavailable;
       
        return await updateJourneySet(journeySet);
    } else {
        return deleteObject("journeySet", journeySetId);   
    }
}

/**
 * Returns all quadrants from index db
 * @param subType quadrant sub-type filter predicate
 * @param shouldDecrypt determines if the returned value is decrypted
 */
export async function getQuadrants(subType: string, shouldDecrypt = true): Promise<Quadrant[]> {
    return getAllObjects("quadrant", subType, shouldDecrypt);
}

/**
 * Returns a given quadrant
 * @param key quadrant primary key
 */
export async function getQuadrant(key: string): Promise<Quadrant> {
    return getObject("quadrant", key);
}

/**
 * Saves a quadrant
 * @param quadrant payload
 */
export async function saveQuadrant(quadrant: Quadrant) {
    saveObject("quadrant", quadrant);
}

/**
 * Updates a quadrant
 * @param quadrant payload
 */
export async function updateQuadrant(quadrant: Quadrant) {
    saveObject("quadrant", quadrant);
}

/**
 * Returns a given journey set, the mixing comes from mismatches in Admin Content Creator and Lambda drivers
 * @param key primary key
 */
export async function getJourneySet(key: string): Promise<JourneySet> {
    // Not ideal to check for both lowercase and uppercase, but the journey set ids in S3
    // kept getting mixed between uppercase and lowercase GUIDs
    // For backwards compatibility with apps in the field we're currently maintaining ids operating in either case
    const lowercaseId = await getObject('journeySet', key.toLowerCase());
    const uppercaseId = await getObject('journeySet', key.toUpperCase());
    return new Promise((resolve) => {
        resolve(lowercaseId || uppercaseId);
    });
}

/**
 * Update journeyset
 * @param journeySet payload
 */
export async function updateJourneySet(journeySet: JourneySet) {
    update("journeySet", journeySet.id, journeySet, true);
}

/**
 * Returns all journeysets from index db
 * @param shouldDecrypt determines if result set is decrypted
 * @param whereClause predicate filter
 */
export async function getJourneySets(shouldDecrypt = true, whereClause = ""): Promise<JourneySet[]> {
    const journeySets = (await getAllObjects("journeySet", "", shouldDecrypt)) as JourneySet[];

    if (whereClause) {
        return journeySets
            .filter((journeySet) => journeySet.quadrantId === whereClause)
            .map((journeySet) => {
                return decryptEntry(journeySet);
            });
    }
    return journeySets;
}

/**
 * Save a journey set and all of it's sub-properties
 * @param journeySet payload
 */
export async function saveJourneySetAndData(journeySet: JourneySet) {
    journeySet.alreadyDownloaded = true;
    if (journeySet.icon) {
        journeySet.iconId = uuidv4();
        await saveMedia({ id: journeySet.iconId, content: journeySet.icon || "" });
    }
    await saveJourneySet(journeySet);

    for (const journey of journeySet.journeys || []) {
        if (journey.audioFile) {
            journey.audioFileId = uuidv4();
            await saveMedia({id: journey.audioFileId , content: journey.audioFile || "" });
        }
        //Force it into this format so it loads faster on the Journeys page
        await saveJourney({
            id: journey.id,
            name: journey.name,
            description: journey.description,
            audioFileId: journey.audioFileId,
            journeySetId: journeySet.id,
            isComplete: false,
            tabs: journey.tabs,
            order: journey.order
        });
    }
}


export async function saveJourneySet(journeySet: JourneySet) {
    saveObject("journeySet", journeySet);
}

/**
 * Retrieve all journey sets
 * @param shouldDecrypt determines if result set is decrypted
 * @param whereClause filter predicate
 */
export async function getJourneys(shouldDecrypt = true, whereClause = ""): Promise<Journey[]> {
    const journeys = (await getAllObjects("journey", "", shouldDecrypt, "tabs")) as Journey[];
    if (whereClause) {
        return journeys
            .filter((journey) => journey.journeySetId === whereClause.toLowerCase() || journey.journeySetId === whereClause.toUpperCase())
            .map((journeyToDecrypt) => {
                return decryptEntry(journeyToDecrypt, journeyToDecrypt.tabs, "tabs");
            }).sort((journey1, journey2) => journey1.order - journey2.order);
    }
    return journeys.sort((journey1, journey2) => journey1.order - journey2.order);
}

export async function getJourney(key: string): Promise<Journey> {
    return getObject("journey", key, true, "tabs");
}

export async function saveJourney(journey: Journey) {
    saveObject("journey", journey, "tabs");
}

export async function updateJourney(journey: Journey) {
    update("journey", journey.id, journey, true, "tabs");
}

export async function getLocaleMessages(): Promise<any> {
    const encryptedLanguage = await getObject("language", 1, false);
    const language = decryptToJson(encryptedLanguage);

    for (const key of LANGUAGE_KEYS) {
        if (key !== "language") {
            const encryptedObj = await getObject(key, 1, false);
            language.messages[key] = decryptToJson(encryptedObj);
        }
    }

    return language;

}

export async function getPreferences(): Promise<Preferences> {
    return getObject("preferences", 1);
}
export async function savePreferences(preferences: Preferences): Promise<void> {
    await update("preferences", 1, preferences, true);
    store.dispatch("setPreferences", preferences);
}

export async function getMediaItem(key: string): Promise<Media> {
    return getObject("media", key);
}

export async function getMedia(shouldDecrypt = true): Promise<Media[]> {
    return getAllObjects("media", "", shouldDecrypt);
}
export async function saveMedia(media: Media): Promise<void> {
    saveObject("media", media);
}

export async function getCategories(): Promise<Media[]> {
    return getAllObjects("category");
}
export async function saveCategory(category: Category): Promise<void> {
    saveObject("category", category);
}

/**
 * Recent journey sets are limited to 4, rolls off the oldest before adding a new
 * @param journeyId latest recent journey set
 */
export async function addRecentJourney(journeyId: string): Promise<void> {
    const recentJourneys = await getObject("recentJourneys", 1, false);
    const indexOfExisting = recentJourneys.findIndex((rjId: string) => rjId === journeyId);
    if (indexOfExisting > -1) {
        recentJourneys.unshift(journeyId);
        recentJourneys.splice(indexOfExisting+1, 1);
    }
    else {
        if (recentJourneys.length >= 4) {
            recentJourneys.pop();
        }
        recentJourneys.unshift(journeyId);
    }
    update("recentJourneys", 1, [...recentJourneys]);
}

/**
 * Retrieves recent journey sets and respective properties
 */
export async function getRecentJourneys(): Promise<Journey[]> {
    const recentJourneys = await getObject("recentJourneys", 1, false);
    const journeys: Journey[] = [];
    for (const journeyId of recentJourneys) {
        const journey = await getJourney(journeyId);
        const journeySet = await getJourneySet(journey?.journeySetId);
        if (journey && journeySet) {
            const media = await getMediaItem(journeySet.iconId);
            journey.icon = getImageDataUri(media.content);
            journeys.push(journey);
        }
    }
    return journeys;
}

export async function addFileUri(uri: string): Promise<void> {
    const uris = await getObject("fileUris", 1, false);
    uris.push(uri);
    await update("fileUris", 1, [...uris]);
}

export async function getFileUris(): Promise<string[]> {
    return getObject("fileUris", 1, false);
}

export async function clearFileUris(): Promise<void> {
    await update("fileUris", 1, []);
}

export interface Quadrant {
    id: string;
    name: string;
    altName?: string;
    // foreign key to media store
    iconId: string;
    icon?: string;
    altIcon?: string;
    journeySets?: JourneySet[];
}
export interface JourneySet {
    id: string;
    name: string;
    pdfName: string;
    // foreign key to media store
    iconId: string;
    icon?: string;
    // foreign key to journey store 
    quadrantId: string;
    // foreign key to category store
    categoryId?: string;
    isDefaultSet: boolean;
    tabContentImages: string[];
    tabImages: string[];
    journeys?: Journey[];
    order: number;
    published?: Date;
    locale?: string;
    alreadyDownloaded: boolean | undefined;
    numberOfJourneys: number;
    updateAvailable: boolean;
    deleted: boolean;
    unavailableToDownload: boolean | undefined;
}

export interface JourneySetToDisplay extends JourneySet {
    imageValue: string;
}

export interface Journey {
    id: string;
    name: string;
    description: string;
    isComplete: boolean;
    audioFileId?: string;
    audioFile?: string;
    videoFileId?: string;
    videoFile?: string;
    // foreign key to journey set store
    journeySetId: string;
    tabs: JourneyTab[];
    icon?: string;
    order: number;
}

export interface JourneyTab {
    tabName: string;
    contentText: string;
    isActive?: boolean;
    audioStartTime?: number;
    audioEndTime?: number;
    text1: string;
    text2: string;
    videoUrl?: string;
    videoLabel?: string;
}

export interface Media {
    id: string;
    content: string;
}

export interface Category {
    id: string;
    name: string;
}

export interface Preferences {
    shouldUseSafetyCalculator: boolean;
    shouldDisplayQuickExit: boolean;
    entrancePin: string;
    lastVisitedRoute: string;
    deletePin: string;
    shouldDisplayUnencryptedAlert: boolean;
    shouldDisplayInternetAlert: boolean;
    shouldDisplayAppSharedAlert: boolean;
    shouldSetPin: boolean;
    canAccessApp: boolean;
}

export interface Language {
    locale: string;
    messages: Record<string, unknown> | string;
}

interface DataEvent extends Event {
    target: any;
}
