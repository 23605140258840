import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import {savePreferences, getPreferences, getMinRequiredDataForAppLoad} from "@/storage/DataStorage";
import {store} from "@/store";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/calculator",
        name: "calculator",
        component: () => import("@/views/Calculator/CalculatorMain.vue"),
    },
    {
        path: "/fields",
        name: "fields",
        component: () => import("@/views/Fields.vue"),
    },
    {
        path: "/privacy",
        name: "privacy",
        component: () => import("@/views/PrivacyPage.vue"),
    },
    {
        path: "/coach",
        name: "coach",
        component: () => import("@/views/Coach.vue"),
    },
    {
        path: "/fields/:fieldId/learn-more",
        name: "learn-more",
        component: () => import("@/views/JourneySets.vue"),
    },
    {
        path: "/fields/:fieldId/more-topics",
        name: "more-topics",
        component: () => import("@/views/JourneySets.vue"),
    },
    {
        path: "/fields/:fieldId/journey-sets",
        name: "journey-sets",
        component: () => import("@/views/JourneySets.vue"),
    },
    {
        path: "/fields/:fieldId/journey-sets/:journeySetId/journeys",
        name: "journeys",
        component: () => import("@/views/JourneyList.vue"),
    },
    {
        path: "/fields/:fieldId/journey-sets/:journeySetId/journey/:journeyId",
        name: "journey",
        component: () => import("@/views/Journey/JourneyPage.vue"),
    },
    {
        path: "/security",
        name: "security",
        component: () => import("@/views/SecurityPage.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach(async (nextRoute) => {
    try {
        let preferences = store.getters.getPreferences();
        const isFirstTimeOpeningApp = store.getters.isFirstTimeOpeningApp();
        const needToSetPin = store.getters.getNeedToSetPin();
        
        if (needToSetPin) {
            return false;
        }

        //Apps that use the calc or pin screen on startup redirect to '/calc' after '/'
        //but we only want to run this once
        if (isFirstTimeOpeningApp && nextRoute.fullPath === '/') {
            await getMinRequiredDataForAppLoad();
        } else {
            preferences = await getPreferences();
            //("Index.ts , local appversion: " + store.state.appVersion);
            await store.dispatch("setPreferences", preferences);
        }
        
        if (!["/", "/calculator"].includes(nextRoute.fullPath)) {
            preferences.lastVisitedRoute = nextRoute.fullPath;
            
            //savePreferences() takes care of this, but we do this here because
            //we don't want to have to wait on the indexedDB on initial app load
            await store.dispatch("setPreferences", preferences);
            
            savePreferences(preferences);
        } else if (preferences.shouldSetPin) {
            return true;
        } else if (!preferences.shouldUseSafetyCalculator) {
            return { path: preferences.lastVisitedRoute};
        }
    } catch {
        return false;
    }
});

export default router;
